:root {
  --rh-table--wrappers--width: auto;
}

rh-table {
  display: block;
  max-width: 100%;
  margin: 2rem 0;
}

rh-table.full-screen[class][class] {
  position: relative;
  z-index: 100;
}

rh-table[class][class] table {
  margin: 0;
  table-layout: fixed;
  border: 0;
  border-collapse: collapse;
}

rh-table[class][class] table caption {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  color: currentColor;
  color: var(--pfe-table__caption--Color, currentColor);
  font-weight: bold;
  text-align: center;
}

rh-table[class][class] table td,
rh-table[class][class] table th {
  padding: 0.5em 1rem;
  border-right: 0;
  border-bottom: 1px solid #d2d2d2;
  border-bottom: 1px solid var(--pfe-table--Border, #d2d2d2);
  border-left: 0;
  -webkit-transition: background 0.25s ease-out;
  transition: background 0.25s ease-out;
}

rh-table[class][class] table td.content--lg,
rh-table[class][class] table th.content--lg {
  min-width: 16em;
}

rh-table[class][class] table td.content--md,
rh-table[class][class] table th.content--md {
  min-width: 10em;
}

rh-table[class][class] table td.halign-left,
rh-table[class][class] table th.halign-left {
  text-align: left;
}

rh-table[class][class] table td.halign-center,
rh-table[class][class] table th.halign-center {
  text-align: center;
}

rh-table[class][class] table td.halign-right,
rh-table[class][class] table th.halign-right {
  text-align: right;
}

rh-table[class][class] table td.valign-top,
rh-table[class][class] table th.valign-top {
  vertical-align: top;
}

rh-table[class][class] table td.valign-middle,
rh-table[class][class] table th.valign-middle {
  vertical-align: middle;
}

rh-table[class][class] table td.valign-bottom,
rh-table[class][class] table th.valign-bottom {
  vertical-align: bottom;
}

rh-table[class][class] table thead td,
rh-table[class][class] table thead th {
  padding-top: 1.5em;
  border-top: 0;
  font-weight: 600;
  background: transparent;
  background: var(--pfe-table__th--Background, transparent);
}

rh-table[class][class] table .rh-table__sort-button {
  position: relative;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  background: transparent;
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

rh-table[class][class] table .rh-table__sort-button svg {
  -webkit-align-self: center;
      -ms-flex-item-align: center;
              -ms-grid-row-align: center;
          align-self: center;
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
      -ms-flex-order: 1;
          order: 1;
  position: relative;
  width: 22px;
  height: 22px;
  margin-left: 0.5em;
  padding: 3px;
  border-radius: 50%;
}

rh-table[class][class] table .rh-table__sort-button .arrow--up,
rh-table[class][class] table .rh-table__sort-button .arrow--down {
  fill: #151515;
  opacity: 0.3;
}

rh-table[class][class] table .rh-table__sort-button:hover svg {
  background: #fff;
}

rh-table[class][class] table .rh-table__sort-button:hover .arrow--down,
rh-table[class][class] table .rh-table__sort-button:hover .arrow--up {
  fill: #06c;
}

rh-table[class][class] table .rh-table__header--sorted-by--za .arrow--down {
  opacity: 1;
}

rh-table[class][class] table .rh-table__header--sorted-by--az .arrow--up {
  opacity: 1;
}

body.rh-table--is-full-screen {
  overflow: hidden;
}

rh-table.full-screen {
  z-index: 1000;
}

rh-table[class][class].full-screen table {
  margin: 0 auto;
}

/*# sourceMappingURL=rh-table--lightdom.css.map */
